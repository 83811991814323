import SectionHeading from './SectionHeading'
import SecGroup from './SecGroup'
import {subscribeImg} from '../../../utils/allImgs'
import SecSocialList from './SecSocialList'

const SecSubscribe = ({data}) => {

  return (
    <section className="container " style={{paddingBottom: '0px'}} id="start">
        <div className="subscribe">
            <div className="row">
                <div className="col-sm-12">
                    <div className="subscribe-wrapper">
                        <div className="col-lg-4 col-md-4 col-xs-12 text-center">
                            <img src={subscribeImg} alt=""/>
                        </div>
                        <div className="col-lg-8 col-md-8 col-xs-12 text-left">
                            <SectionHeading />
                            <div className="service-text">
                                
                                <SecGroup />
                                <SecSocialList data={data} />
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>  
    </section>
  );
}

export default SecSubscribe;