import SectionHeading from '../../../components/SectionHeading'
import SecVideoArea from './SecVideoArea'

const SecDemoVideo = ({img}) => {

  return (
    <section className="demo-video section-before section-padding-100" id='Webinars'>
        <div className="container">
            <SectionHeading
                title='Webinars'
                text='Join our online webinars to learn about industry trends, market forecasts, and investment strategies. Regular interactive sessions allow you to engage with experts and fellow investors.'
            />
            <SecVideoArea img={img} />
        </div>
    </section>
  );
}

export default SecDemoVideo;