import SectionHeading from '../../../components/SectionHeading'

const SecPartners = ({data}) => {

  return (
    <section className="section-padding-100-0" id='Mission'>

        <SectionHeading
            title='Our Mission'
            text='Our mission is to help global investors better understand and invest in the cryptocurrency market, providing transparent, secure, and efficient investment services.'
        />

        <div className="container">
            <div className="row">
                {data && data.map((item , key) => (
                    <div className="col-lg-2 col-sm-6" data-aos="fade-up" key={key}>
                        <div className="partner-box">
                            <img src={item.img} alt="" className="center-bock" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
  );
}

export default SecPartners;