const Span = () => <span></span>

const SecWhoWeContant = () => {

  return (
    <div className="col-12 col-lg-6">
        <div className="who-we-contant">
            <div className="dream-dots" data-aos="fade-up">
                {Array(7).fill().map((item,key) => (
                		<Span key={key}/>
                	))}
            </div>
            <h4 data-aos="fade-up">Expert Analysis</h4>
            <p data-aos="fade-up">
            In a rapidly changing market environment, timely access to deep market insights is key to successful investment. Our team of experts leverages extensive data analysis, industry research, and global trend tracking to provide you with precise market analysis, helping you seize investment opportunities.</p>
            {/* <p data-aos="fade-up">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore eius molestiae facere, natus reprehenderit eaque eum, autem ipsam. Magni, error. Tempora odit laborum iure inventore possimus laboriosam qui nam. Fugit!</p> */}
            {/* <a className="btn dream-btn mt-30" href="#">Read More</a> */}
        </div>
    </div>
  );
}

export default SecWhoWeContant;