import './SectionHeading.css'

const SectionHeading = () => {

  return (
    <div className="section-heading ">
        <h2 className="title-head-subscribe" data-aos="fade-up">Don’t Miss CryptoPulse News And Updates!</h2>
        <p className="text-head-subscribe" data-aos="fade-up"></p>
    </div>
  );
}

export default SectionHeading;