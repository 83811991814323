const Span = () => <span></span>

const SecWhoWeContant = () => {

  return (
    <div className="col-12 col-lg-6">
        <div className="who-we-contant">
            <div className="dream-dots">
                {Array(7).fill().map((item,key) => (
                		<Span key={key}/>
                	))}
            </div>
            <h4 data-aos="fade-up">Diversified Portfolio</h4>
            <p data-aos="fade-up">Diversifying risk across various assets is one of the core principles of investment strategy.  By reasonably allocating investments among different asset types, you can effectively reduce the impact of individual asset volatility on the overall portfolio, helping to achieve more stable returns.</p>
            {/* <p data-aos="fade-up">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore eius molestiae facere, natus reprehenderit eaque eum, autem ipsam. Magni, error. Tempora odit laborum iure inventore possimus laboriosam qui nam. Fugit!</p> */}
            {/* <a data-aos="fade-up" className="btn dream-btn mt-30" href="#">Read More</a> */}
        </div>
    </div>
  );
}

export default SecWhoWeContant;