import {HomeDemo1money} from '../../../../utils/allImgs'

function SecWelcomeContent({img}){
  return(
      <div className="welcome-content">
          <div className="promo-section">
              <div className="integration-link">
                  <span className="integration-icon">
                      <img src={img} width="24" height="24" alt="" />
                  </span>
                  <span className="integration-text">CryptoPulse your best choice</span>
              </div>
          </div>
          <h1>Invest in the Future of Finance <img src={HomeDemo1money} alt="monryImg" /></h1>
          <p>
          Stay ahead of the curve with CryptoPulse Invest
          </p>
          <div className="dream-btn-group">
              <a href="#" className="btn dream-btn mr-3">Get Started</a>
              {/* <a href="#" className="btn dream-btn">Contact Us</a> */}
          </div>
      </div>
  )
}

export default SecWelcomeContent