import { useEffect } from 'react'

import { Logo } from '../../data/data-layout/data-Header.js';

import { Addshrink, addActiveClass, OpenMenu, moveSmooth } from "../../utils/"

import './header.css'

import Preloader from '../../components/Preloader'

const Header = () => {

  useEffect(() => {
    Addshrink()
  }, [])

  useEffect(() => {
    OpenMenu()
  }, [])

  useEffect(() => {
    moveSmooth()
  }, [])

  return (
    <>
      <Preloader />
      <header className="header-area wow fadeInDown" data-wow-delay="0.2s">
        <div className="classy-nav-container breakpoint-off">
          <div className="container">
            <nav className="classy-navbar justify-content-between" id="dreamNav">
              <a className="nav-brand" href="#"><img src={Logo} alt="logo" /></a>
              <div className="classy-navbar-toggler">
                <span className="navbarToggler" onClick={addActiveClass}>
                  <span />
                  <span />
                  <span />
                </span>
              </div>
              <div className="classy-menu">
                <div className="classycloseIcon">
                  <div className="cross-wrap" onClick={addActiveClass}>
                    <span className="top" />
                    <span className="bottom" />
                  </div>
                </div>
                <div className="classynav">
                  <ul id="nav">
                    <li className="menu-item">
                      {/* <a onClick={moveSmooth} href="#home">Home</a> */}
                      <a href="#Invest">Invest</a>
                      <div className="submenu">
                        <a className='submenu-a' onClick={moveSmooth} href="#Investment Strategies">Investment Strategies</a>
                        <a className='submenu-a' onClick={moveSmooth} href="#Portfolio">Portfolio</a>
                        <a className='submenu-a' onClick={moveSmooth} href="#">Calculator</a>
                      </div>
                    </li>
                    <li className="menu-item">
                      <a href="#CryptoBasics">Learn</a>
                      <div className="submenu">
                        <a className='submenu-a' onClick={moveSmooth} href="#CryptoBasics">CRYPTO BASICS</a>
                        <a className='submenu-a' onClick={moveSmooth} href="#MarketAnalysis">Market Analysis</a>
                        <a className='submenu-a' onClick={moveSmooth} href="#Webinars">Webinars</a>
                      </div>
                    </li>
                    <li className="menu-item">
                      <a href="#team">About</a>
                      <div className="submenu">
                        <a className='submenu-a' onClick={moveSmooth} href="#team">Our Team</a>
                        <a className='submenu-a' onClick={moveSmooth} href="#Mission">Mission</a>
                        <a className='submenu-a' onClick={moveSmooth} href="#Faq">FAQ</a>
                      </div>
                    </li>
                    <li className="menu-item">
                      <a href="#Email">Contact</a>
                      <div className="submenu">
                        <a className='submenu-a' onClick={moveSmooth} href="#Email">Email</a>
                        <a className='submenu-a' onClick={moveSmooth} href="#Phone">Phone</a>
                        <a className='submenu-a' onClick={moveSmooth} href="#SocialMedia">Social Media</a>
                      </div>
                    </li>
                  </ul>
                  <div className='ml-50 ligin-div'></div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;