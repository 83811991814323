import SectionHeading from '../../../components/SectionHeading'
import Timeline from './Timeline'

const SecOurRoadmap = ({data}) => {

  return (
    <section className="roadmap" style={{paddingBottom:0,paddingTop:100}}  id="Portfolio" >
        <SectionHeading
            title='Our Portfolios'
            text="We offer a range of portfolios tailored to different risk preferences and goals. Whether you're seeking long-term stability or short-term high growth, CryptoPulse Invest can create a customized portfolio for you."
        />
        <div className="container">
          <div className="row">
            <div className="timeline-split">
              <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="timeline section-box-margin">
                      {data && data.map((item , key) => (
                          <Timeline
                            key={key}
                            timelineClass={item.timelineClass}
                            title={item.title}
                            date_from={item.date_from}
                            date_to={item.date_to}
                            text={item.text}
                          />
                        ))}
                      <div className="circle"></div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
}

export default SecOurRoadmap;