import {
    HomeDemo1IcoPlatforms1,
    HomeDemo1IcoPlatforms2,
    HomeDemo1IcoPlatforms3,
    HomeDemo1IcoPlatforms4,
    HomeDemo1IcoPlatforms5,
    HomeDemo1IcoPlatforms6,

    HomeDemo1Services1,
    HomeDemo1Services2,
    HomeDemo1Services3,
    HomeDemo1Services4,
    HomeDemo1Services5,
    HomeDemo1Services6,

    HomeDemo1TokenIcon1,

    HomeDemo1TeamImg1,
    HomeDemo1TeamImg2,
    HomeDemo1TeamImg3,
    HomeDemo1TeamImg4,
    
    HomeDemo1Partners1,
    HomeDemo1Partners2,
    HomeDemo1Partners3,
    HomeDemo1Partners4,
    HomeDemo1Partners5,
    HomeDemo1Partners6,
    HomeDemo1Partners7,
    HomeDemo1Partners8,
    HomeDemo1Partners9,
    HomeDemo1Partners10,
    HomeDemo1Partners11,
    HomeDemo1Partners12,

    HomeDemo2RoadmapIcons1,
    HomeDemo2RoadmapIcons2,
    HomeDemo2RoadmapIcons3,
    HomeDemo2RoadmapIcons4,
    HomeDemo2RoadmapIcons5,
    HomeDemo2RoadmapIcons6,
    HomeDemo2RoadmapIcons7,
    HomeDemo2RoadmapIcons8,
    HomeDemo2RoadmapIcons9,

    HomeDemo1BlogImg1,
    HomeDemo1BlogImg2,
    HomeDemo1BlogImg3

} from '../../utils/allImgs'

export const VerticalSocial = [
    {nameIco:'fa fa-telegram'},
    {nameIco:'fa fa-medium'},
    {nameIco:'fa fa-facebook'},
    {nameIco:'fa fa-twitter'},
    {nameIco:'fa fa-github'},
    {nameIco:'fa fa-instagram'},
    {nameIco:'fa fa-linkedin'}
]

export const SingleCoolFact = [
    {
        img:HomeDemo1IcoPlatforms1,
        ico_check:true
    },
    {
        img:HomeDemo1IcoPlatforms2,
        ico_check:false
    },
    {
        img:HomeDemo1IcoPlatforms3,
        ico_check:true
    },
    {
        img:HomeDemo1IcoPlatforms4,
        ico_check:true
    },
    {
        img:HomeDemo1IcoPlatforms5,
        ico_check:false
    },
    {
        img:HomeDemo1IcoPlatforms6,
        ico_check:true
    }
]

export const service_single_content = [
    {
        img:HomeDemo1Services1,
        title:'Long-Term Growth',
        text:'Ride the waves of market trends and unlock the long-term potential of crypto assets.',
    },
    {
        img:HomeDemo1Services2,
        title:'Risk Management',
        text:'Protect investments with expertise, reducing the risks of market volatility.',
    },
    {
        img:HomeDemo1Services3,
        title:'High-Growth Potential Assets',
        text:'Invest in emerging projects with high growth potential for greater returns.',
        
    },
    {
        img:HomeDemo1Services4,
        title:'Short-Term Gains',
        text:'Capture short-term opportunities for quick growth.',
    },
    {
        img:HomeDemo1Services5,
        title:'Diversification',
        text:'Spread risk across diverse assets for a balanced portfolio.',
    },
    {
        img:HomeDemo1Services6,
        title:'Market Hedging',
        text:'Use hedging strategies to balance volatility and reduce market risk.',
    }

]

export const timelineInfo = [
    {
        timelineClass:"block block-left",
        title:'Conservative Portfolio',
        date_to:'Suitable for investors with a low risk tolerance, focusing on stable growth',
        text:'Composed of stablecoins and low-volatility crypto assets, the Conservative Portfolio targets low risk, ideal for those seeking steady returns.',
    },
    {
        timelineClass:"block block-right mt-30",
        title:'Balanced Portfolio',
        date_to:'Ideal for investors who wish to balance risk and return.',
        text:'Combining mainstream coins with select emerging assets, the Balanced Portfolio aims for stable returns with growth potential.',
    },
    {
        timelineClass:"block block-left mt-30",
        title:'Growth Portfolio',
        date_to:'Suitable for investors who are willing to take on higher risks for greater growth.',
        text:'Focused on mainstream coins and high-growth crypto assets, the Growth Portfolio targets long-term value appreciation, ideal for investors with a higher risk tolerance.',
    },
    {
        timelineClass:"block block-right mt-30",
        title:'High-Risk, High-Reward Portfolio',
        date_to:'Ideal for aggressive investors willing to take on high risk for high rewards.',
        text:'Including emerging projects and volatile crypto assets, this portfolio aims to capture high-reward opportunities for risk-tolerant investors.',
    }
]

export const timelineInfoTwo = [
    {
        left:"10%",
        ClassName:"data_cir cir_0",
        date:"Q2 2017",
        IsSpan:true,
        TextSpan:"Emergence and design of the idea",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons1

    },
    {
        left:"20%",
        ClassName:"data_cir cir_1",
        date:"Q3 2017",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:true,
        TextFourLi1:"built our team",
        TextFourLi2:"company establishment",
        TextFourLi3:"legal issues setting",
        TextFourLi4:" concept development",
        img:HomeDemo2RoadmapIcons2

    },
    {
        left:"30%",
        ClassName:"data_cir cir_2",
        date:"Q4 2017",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:true,
        TextTowLi1:'start development of the ealier version and crypto wallet version',
        TextTowLi2:'negotiations on partnerships',
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons3

    },
    {
        left:"40%",
        ClassName:"data_cir cir_3",
        date:"Q1 2024",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"website launch",
        TextThreeLi2:"White Paper published",
        TextThreeLi3:"Private and Pre-sale start",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons4

    },
    {
        left:"50%",
        ClassName:"data_cir cir_4 red_c",
        date:"Q2 2024",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Crowdsale",
        TextThreeLi2:"marketing campaign",
        TextThreeLi3:"listing to the major exchanges",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons5

    },
    {
        left:"60%",
        ClassName:"data_cir cir_5 next",
        date:"Q3 2024",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Crypto Wallet version release",
        TextThreeLi2:"Testnet launch",
        TextThreeLi3:" Platform ealier version development",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons6

    },
    {
        left:"70%",
        ClassName:"data_cir cir_6 next",
        date:"Q4 2024",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Mobile App",
        TextThreeLi2:"Explorer",
        TextThreeLi3:"new partnerships and new marketplace support",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons7

    },
    {
        left:"80%",
        ClassName:"data_cir cir_7 next",
        date:"Q1 2019",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Mainnet launch",
        TextThreeLi2:"Wallet beta-version release",
        TextThreeLi3:"Masternodes implementation",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons8

    },
    {
        left:"90%",
        ClassName:"data_cir cir_8 next",
        date:"Q2 2019",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:true,
        TextTowLi1:"Platform integration to electronic marketplaces",
        TextTowLi2:"Zero Fee",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons9

    }
]

export const ServiceBlock = [
    {
        classIco:"icon ti-mobile",
        title:"What is Cryptocurrency?",
        text:"Cryptocurrency is a digital currency based on blockchain technology, not reliant on traditional banks or government issuance. It uses cryptographic techniques to secure transactions, ensuring the safe transfer of assets in a decentralized network."
    },
    {
        classIco:"icon ti-widget",
        title:"What is Blockchain?",
        text:"Blockchain is a decentralized distributed ledger technology where all transaction records are stored in a publicly transparent database. Each block contains transaction information and is linked to the previous block, forming an immutable chain."
    },
    {
        classIco:"icon ti-settings",
        title:"How to Buy Cryptocurrency?",
        text:"Cryptocurrency can typically be purchased through crypto exchanges. You need to create an account, complete identity verification, and then buy crypto using bank transfers, credit cards, or other payment methods.",
    },
]

export const SocialListIco = [
    {icoName:"fa fa-facebook-square"},
    {icoName:"fa fa-twitter-square"},
    {icoName:"fa fa-github-square"},
    {icoName:"fa fa-instagram"},
    {icoName:"fa fa-medium"}
]

export const FQAInfo = [
    {
        text:'How do I start investing in cryptocurrency?',
        ID:'Q1',
        content:"To start investing in cryptocurrency, you first need to sign up on our platform and complete the identity verification. Then, you can purchase the cryptocurrencies you're interested in via bank transfer or other payment methods. We provide a step-by-step guide to help you through the investment process."
    },
    {
        text:'Can I adjust my investment portfolio at any time?',
        ID:'Q2',
        content:'Yes, you can adjust your investment portfolio at any time. We offer flexible investment options, allowing you to modify your strategies based on market trends and personal preferences.'
    },
    {
        text:'How is the platform’s security ensured?',
        ID:'Q3',
        content:'Our platform uses the latest security technologies, including encrypted transmissions and two-factor authentication (2FA), to ensure the safety of your personal information and funds. We also conduct regular security audits to protect your assets from potential threats.'
    },
    {
        text:'The cryptocurrency market is volatile, how do I manage risk?',
        ID:'Q4',
        content:'We recommend that you create a diversified portfolio based on your risk tolerance and financial goals. By spreading your investments, you can effectively mitigate the risks associated with the volatility of individual assets. Our expert team also provides market analysis and investment advice.'
    },
]

export const DocElementTitle = [
    {
        title:'WhitePaper'
    },
    {
        title:'OnePager'
    },
    {
        title:'Rating Review'
    },
    {
        title:'Marketing Plan'
    },
]

export const TokenText = [
    {
        text:'The crypto market is known for its high volatility, with significant price swings that can result in high returns or risks. Understanding volatility helps investors make informed decisions.',
        img:HomeDemo1TokenIcon1
    },
    {
        text:'Technical analysis uses historical data and charts to predict market trends, helping investors identify potential buy or sell signals.',
        img:HomeDemo1TokenIcon1
    },
    {
        text:'Fundamental analysis focuses on the intrinsic value of a project, including factors like the team, technology, and market demand, helping to identify long-term investment opportunities.',
        img:HomeDemo1TokenIcon1
    },
    {
        text:'Market sentiment reflects the emotions and expectations of investors, often analyzed through news, social media, and market behavior to predict price movements.',
        img:HomeDemo1TokenIcon1
    },
]

export const MissionData = [
    {
        icoName:"ti-shine",
        title:"Quality"
    },
    {
        icoName:"ti-ruler-pencil",
        title:"Creativity"
    },
    {
        icoName:"ti-heart",
        title:"Reliability"
    },
]

export const TeamMember = [
    {
        img:HomeDemo1TeamImg1,
        title:'Oliver Reed',
        text:'CEO',
        content:'Oliver is the founder and CEO of CryptoPulse Invest, with over 10 years of experience in the finance and blockchain industries. As CEO, he is responsible for the strategic direction and overall operations, driving innovation in crypto investment services.'
    },
    {
        img:HomeDemo1TeamImg2,
        title:'Sophia Gray',
        text:'Director of Investments',
        content:'Sophia has extensive experience in market analysis and portfolio management, having consulted for several renowned financial institutions. As Director of Investment Strategies, she leads the team in developing effective investment portfolios and market strategies.'
    },
    {
        img:HomeDemo1TeamImg3,
        title:'Liam Foster',
        text:'CTO',
        content:"Liam is a key member of our tech team with extensive experience in software development and blockchain technology. He oversees the design and implementation of the company's technical architecture, ensuring platform security, reliability, and scalability."
    },
    {
        img:HomeDemo1TeamImg4,
        title:'Amelia Scott',
        text:'Marketing Director',
        content:'Amelia has years of experience in marketing, especially in the cryptocurrency and blockchain sectors. As Marketing Director, she is responsible for brand development, marketing campaigns, and maintaining relationships with investors and partners.'
    }
]

export const PartnersData = [
    {img:HomeDemo1Partners1},
    {img:HomeDemo1Partners2},
    {img:HomeDemo1Partners3},
    {img:HomeDemo1Partners4},
    {img:HomeDemo1Partners5},
    {img:HomeDemo1Partners6},
    {img:HomeDemo1Partners7},
    {img:HomeDemo1Partners8},
    {img:HomeDemo1Partners9},
    {img:HomeDemo1Partners10},
    {img:HomeDemo1Partners11},
    {img:HomeDemo1Partners12}
]

export const BlogPost = [
    {img:HomeDemo1BlogImg1},
    {img:HomeDemo1BlogImg2},
    {img:HomeDemo1BlogImg3}
]
