const SecContent = () => {
	return(
        <div className="col-12 col-md-7" id="Phone">
          {/* Content Info */}
          <div className="contact_info_area d-sm-flex justify-content-around">
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.2s">
              <h5>NAVIGATE</h5>
              <a href='#Invest'><p>Invest</p></a>
              <a  href='#CryptoBasics'><p>Learn</p></a>
              <a  href='#team'><p>About</p></a>
              <a  href='#Email'><p>Contact</p></a>
            </div>
            {/* Content Info */}
            {/* <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.3s">
              <h5>PRIVACY &amp; TOS</h5>
              <a  href='#'><p>Advertiser Agreement</p></a>
              <a  href='#'><p>Acceptable Use Policy</p></a>
              <a  href='#'><p>Privacy Policy</p></a>
              <a  href='#'><p>Technology Privacy</p></a>
              <a  href='#'><p>Developer Agreement</p></a>
            </div> */}
            {/* Content Info */}
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.4s">
              <h5>Phone</h5>
              {/* <p>Mailing Address:xx00 E. Union Ave</p>
              <p>Suite 1100. Denver, CO 80237</p> */}
              <p>+1 (769) 297‑7036</p>
              <p>Info@cryptopulse.biz</p>
            </div>
          </div>
        </div>
	)
}

export default SecContent