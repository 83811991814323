const Timeline = ({timelineClass , title ,date_from ,date_to,text}) => {

  return (
      <div className={timelineClass}>
         <h3>{title}</h3>
         <span className="date">target audience</span><span className="between">{date_to}</span>
         <p>{text}</p>
      </div>
  );
}

export default Timeline;