const SecIco = ({logo}) => {
	return(
        <div className="col-12 col-md-5" id="SocialMedia">
          <div className="footer-copywrite-info">
            {/* Copywrite */}
            <div className="copywrite_text wow fadeInUp" data-wow-delay="0.2s">
              <div className="footer-logo">
                <a href="#"><img src={logo} alt="logo" /></a>
              </div>
              
              <p>At CryptoPulse, we're passionate about cryptocurrency and its potential to change the world.     Our goal is to provide you with the knowledge, resources, and expertise to confidently navigate the crypto market and achieve your financial goals.</p>
              <p>Explore our website, join our community, and start your crypto journey today!</p>
            </div>
            {/* Social Icon */}
            {/* <div className="footer-social-info wow fadeInUp" data-wow-delay="0.4s">
              <a href="#"><i className="fa fa-facebook" aria-hidden="true" /></a>
              <a href="#"> <i className="fa fa-twitter" aria-hidden="true" /></a>
              <a href="#"><i className="fa fa-google-plus" aria-hidden="true" /></a>
              <a href="#"><i className="fa fa-instagram" aria-hidden="true" /></a>
              <a href="#"><i className="fa fa-linkedin" aria-hidden="true" /></a>
            </div> */}
          </div>
        </div>
	)
}

export default SecIco